// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// CoreUI Icons
@import '~@coreui/icons/css/free.min.css';

@import "layout";

// If you want to add custom CSS you can put it here.
@import "custom";
