// Here you can add other styles
.grid-clickable-title {
    cursor: pointer;
}

.farm-account-table,
.accounts-table,
.confirmations-table,
.drop-detail-table,
.all-drops-table {

    tr,
    th,
    td {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        white-space: nowrap;
    }

    th {
        label {
            color: white;
        }
    }

    td {
        &.m-w-250 {
            min-width: 250px;
        }

        &.button-col {
            padding-left: 1px;
            padding-right: 1px;
        }
    }
}

.bg-d {
    --cui-bg-opacity: 1;
    background-color: rgba(var(--df-dark-rgb), var(--cui-bg-opacity)) !important;
    color: #fff;
}
:root {
    --cui-dark-rgb: 31,28,38;
    --df-dark-rgb: 21,20,26; 
    --cui-link-color: #ffd728;
    --cui-link-hover-color: #ffd728;
    --cui-btn-active-bg: #ffd728;
    --cui-btn-active-border-color: #ffd728;
    --df-primary-color: #ffd728;
  }

.df-title a {
    text-decoration: none;
}
.df-title h1 {
    line-height: 100px;
    font-weight: 300;
    color: var(--df-primary-color);
}
.bg-table-thead {
    background: linear-gradient(82.68deg,#3c3105,#231c27);
    border-top: 1px solid var(--df-primary-color);
    color: #fff;
}
.bg-table-primary {
    background-color: #262230;
    color: #9d93b0;
}
.bg-table-secondary {
    background-color: #1e1b28;
    color: #9d93b0;
}

thead, tbody, tfoot, tr, td, th {
    border-style: none;
}
.df-text {
    color: var(--df-primary-color);

}

.chartjs-tooltip {
    background: rgba(0,0,0,.7);
    border-radius: 0.375rem;
    color: #fff;
    opacity: 0;
    padding: 0.25rem 0.5rem;
    pointer-events: none;
    min-width: 200px;
    position: absolute;
    transform: translate(-50%);
    transition: all .15s ease;
    z-index: 1021;
}

.df-nickname {
    color: var(--df-primary-color);
    margin-left: 15px;
    font-size: 1.7rem;

}
.btn-df:hover {
    background-color: #181521;

}

.bg-df-card {
    background: linear-gradient(82.68deg,#433922,#231c27);
    border: 1px solid #e9b707;
    color: #fff;
}

.btn-df-new {
    text-decoration: none !important;
    color: #fff;
}

.bg-df-card-money {
    background: linear-gradient(82.68deg, #22432b, #231c27);
    border: 1px solid #2eb85c;
    color: #fff;
}

.username {
    cursor: pointer;
}
.username .external {
    opacity: 0;
}
.username:hover .external {
    opacity: 1;
}
.rotate-icon {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.df-text-dark {
    color:rgba(var(--df-dark-rgb));
}
.modal-content {
    background: rgba(var(--df-dark-rgb));
    color: #9d93b0;
}
.modal-footer {
    border-top: 0px;
}

.form-control {
    color: #9d93b0;
    background-color: #181521;
    background-clip: padding-box;
    border: 1px solid #40391f;
}
.form-control:focus {
    background-color: #1E1B26;
    border-color: #eba918;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(221 182 12 / 25%);
    color: #9d93b0;
}
.form-control::placeholder {
    color: #403535;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #ffd728;
    background-color: #15141a;
    border-color: #ffd728;
    border-bottom: 0px;
}
.nav-tabs {
    border: 0px;
}

.nav-tabs .nav-link:hover {
    border-color: #15141a;
}